import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import SearchPage from "./containers/SearchPage";
import PersonInfoPage from "./containers/PersonInfoPage";
import OrderSuccessPage from "./containers/OrderSuccessPage";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Copyright &copy; Альфа-Тур {new Date().getFullYear()}
    </Typography>
  );
}

function App() {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
    main: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2),
    },
    footer: {
      padding: theme.spacing(4),
      marginTop: "auto",
    },
    homeLink: {
      color: "#fff",
      textDecoration: "none",
    },
    appBar: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: "50px",
    },
  }));

  const classes = useStyles();
  
  const [prices, setPrices] = useState({});

  useEffect(() => {
    fetch('./prices.json')
      .then(res => res.json())
      .then(data => setPrices(data))
      .catch(err => console.log(err));
  }, []);
  
  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="static" className={classes.appBar}>
        <Container maxWidth="sm">
          <a href="https://alphatour.ru" className={classes.homeLink}>
            <Typography variant="h6" color="inherit" align="center">
              Альфа-Тур
            </Typography>
          </a>
        </Container>
      </AppBar>
      <Container maxWidth="sm">
        <Route
          path="/" 
          exact 
          render={props => <SearchPage {...props} prices={prices} />} 
        />
        <Route
          path="/personInfo" 
          render={props => <PersonInfoPage {...props} prices={prices} />} 
        />
        <Route 
          path="/orderSuccess" 
          render={props => <OrderSuccessPage {...props} prices={prices} />} 
        />
      </Container>
      <footer className={classes.footer}>
        <Copyright />
      </footer>
    </div>
  );
}

export default App;
