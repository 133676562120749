import React from 'react';
import styled from 'styled-components';

const Policy = styled.div`
  .c7 > * {
    margin: 0;
    padding:0;
    font-family: Arial, Helvetica, sans-serif;
  }
`;

export default (<Policy className="c7">
    <p><strong>Общие положения</strong></p>
    <p><strong>ПУБЛИЧНЫЙ ДОГОВОР (ОФЕРТА)</strong>о предоставлении транспортных услуг от <strong>26.01.2016 г.</strong></p>
    <p>Настоящий Публичный договор (оферта) об оказании транспортных услуг, далее <strong>договор</strong>, определяет взаимоотношения между «<strong>ИП Бадмаев</strong> (торговое название <strong>«Альфа-Тур»</strong>) именуемым в дальнейшем <strong>перевозчик</strong> и любым лицом, осуществившего заказ транспортной услуги у перевозчика оформленным заявкой на сайте или посредством телефонного разговора или и тем самым, принявшим публичное предложение (оферту) о заключении данного <strong>договора</strong> предоставления услуг, именуемым в дальнейшем <strong>клиент</strong>, совместно именуемыми <strong>стороны</strong>.</p> 
    <p>Постоянный адрес сайта: <a href="https://alphatour.ru/">http://alphatour.ru</a></p>

    <p><strong>Настоящий договор оферта</strong></p>
    <div><strong>1. Понятия и термины</strong>, если в тексте настоящего Договора не указано иное, термины и определения имеют следующее значение:</div>
    <ol>
        <li><strong>Компания</strong> или <strong>Перевозчик</strong> – ИП Бадмаев (торговая марка <strong>«Альфа-Тур»</strong>), собственник автомобильного парка и автоматизированного комплекса программно-аппаратного обеспечения, с помощью которого осуществляется прием заказов Клиентов, обработка заказов и передача их непосредственно водителям Компании.</li>
        <li><strong>Договор</strong> — настоящий документ, а также все приложения, изменения и дополнения к нему, оформленные и доведенные до сведения Клиента в соответствии с условиями настоящего документа.</li>
        <li><strong>Акцепт</strong> — принятие Клиентом условий настоящей Оферты.</li>
        <li><strong>Клиент</strong> — Заказчик транспортной услуги принявший настоящий договор Оферты оставивший заказ через сайт или по телефону диспетчеру Перевозчика.</li>
        <li><strong>Заказ</strong> — информация, поступающая от Клиента, содержащая номер телефона Клиента, имя Клиента, дата поездки, количество посадочных мест, грузоемкость и прочие особенности поездки.</li>
        <li><strong>Маршрут</strong> — путь следования транспортного средства между адресами отправления и назначения.</li>
        <li><strong>Трансфер</strong> —транспортная услуга по доставке Клиента из пункта А в пункт Б.</li>
        <li><strong>Стоимость услуги</strong> — плата Клиента по каждому Заказу, оговоренному при подаче заявки.</li>
        <li><strong>Пассажир</strong> – человек, который перевозится транспортным средством Компании по Заказу в соответствии с данным Договором.</li>
        <li><strong>Багаж</strong> – вещи Пассажира, принятые для перевозки.</li>
        <li><strong>Ручная кладь</strong> — вещи Пассажира, которые перевозятся Пассажиром с собой в транспортном средстве и сохранность которых при перевозке обеспечивается Пассажиром.</li>
        <li>Виды рейсов:</li>
            <div>12.1. <strong>Регулярные</strong></div>
            <div>1. Выполняются ежедневно в фиксированное время.</div>
            <div>2. Выполняются в независимости от количества пассажиров.</div>
            <div>12.2. <strong>Дополнительные</strong></div>
            <div>1. Выполняются ежедневно в отличное от фиксированного времени.</div>
            <div>2. Выполняются при наличии необходимого количества пассажиров.</div>
        <li>Время отправления – время до которого происходит сбор пассажиров с адресов.</li>
    </ol>
    <div><strong>2. Предмет Договора</strong></div>
    <ol>
        <li>Настоящий Договор является публичным договором на основании ст. 426 Гражданского Кодекса Российской Федерации (ГК РФ), условия публичного договора устанавливаются одинаковыми для всех Клиентов.</li>
        <li>В соответствии с п. 2 ст. 437 ГК РФ, в случае принятия изложенных в настоящем договоре условий (акцепта) любое физическое лицо, обладающее полной право — и дееспособностью, а также любой уполномоченный представитель юридического лица, обладающий правом на заключение подобных договоров, производя акцепт (осуществляя Заказ) данной оферты, становится Клиентом.</li>
        <li>В соответствии с п. 3 ст. 438 ГК РФ акцепт оферты равносилен заключению договора на условиях, изложенных в Публичном договоре (оферте). Внимательно прочитайте текст данного Договора и если Вы не согласны с каким-либо пунктом Договора, Компания предлагает Вам отказаться от принятия услуг.</li>
        <li>В соответствии со статьей 436 ГК РФ настоящая Оферта не является безотзывной.</li>
        <li>Принятие Договора (Акцепт Оферты) является полным и безоговорочным и означает согласие Клиента со всеми без исключения и дополнения условиями Договора. Акцепт считается принятым с момента осуществления Заказа услуг Клиентом, а также свидетельствует о том, что Клиент понимает значение своих действий, все условия Договора ему понятны, Клиент не находится под влиянием заблуждения, обмана, насилия, угрозы.</li>
        <li>Клиент гарантирует, что имеет полное право и полномочия на заключение и исполнение настоящего Договора при осуществлении Заказа.</li>
        <li>Клиент гарантирует свою платежеспособность.</li>
        <li>Предметом настоящего договора является оказание Компанией транспортной услуг на основании Заказа от Клиента путем исполнения собственным транспортом на условиях настоящего Договора, которые Клиент обязуется оплатить.</li>
        <li>Автомобили предоставляются Компанией в необходимом Клиенту количестве исходя из общего количества пассажиров, подлежащих перевозке, с учетом, что вместимость одного легкового автотранспортного средства — не более 8 пассажиров.</li>
    </ol>
    <div><strong>3. Права и обязанности сторон</strong></div>
    <div style={{marginLeft:'10px'}}>3.1 <strong>Права Компании</strong></div>
    <ol>
        <li>Передавать исполнение Заказа автомобилям Компании.</li>
        <li>Собирать информацию о количестве Заказов осуществляемых Клиантами, их деталях, вести учет поездок Клиента.</li>
        <li>Компания вправе отказать в предоставлении услуги при наличии у Клиента долга в случае неоплаты Заказа либо наличии у Клиента штрафа.</li>
        <li><strong>Отказать в обслуживании Клиента</strong></li>
        <ol>
            <li>На основании Постановления Правительства Российской Федерации № 112 от 14.02.2009 г. «Об утверждении правил перевозок пассажиров и багажа автомобильным транспортом и городским наземным электрическим транспортом» (далее ППРФ № 112) если багаж пассажира не проходит через дверные проемы автомобиля; может загрязнить или испортить сидения; мешает водителю управлять автомобилем или пользоваться зеркалами заднего вида; не позволяет осуществлять его перевозку с закрытой крышкой багажного отделения; включает холодное или огнестрельное оружие без чехлов и упаковки; содержит зловонные и/или опасные (легковоспламеняющиеся, взрывчатые, токсичные, коррозионные и др.) вещества.</li>
            <li>На основании ППРФ № 112, если пассажир не одел на провозимую собаку намордник и поводок, не обеспечил для нее подстилку, если перевозимое мелкое животное не помещено в клетку (корзинку, коробку, сумку) с глухим дном.</li>
            <li>На основании Кодекса об административных правонарушениях Российской Федерации, если пассажир употребляет спиртные напитки в салоне автомобиля (ст. 20.20), находится в состоянии алкогольного опьянения, оскорбляющем человеческое достоинство и общественную нравственность (ст. 20.21), употребляет при заказе автомобиля либо в процессе поездки в отношении сотрудников Компании ненормативную лексику, нецензурную брань (ст. 20.1.1).</li>
            <li>На основании ст. 6 Федерального закона Российской Федерации № 87 от 10.07.2001 г. «Об ограничении курения табака» в случае курения пассажиром в салоне автомобиля.</li>
        </ol>
    </ol>
    <div style={{marginLeft:'10px'}}>3.2 <strong>Компания обязуется</strong></div>
    <ol>
        <li>При невозможности оказания услуги известить об этом Клиента минимум за 12 часов.</li>
        <li>Подать Клиенту автомобиль, с чистым салоном, в исправном техническом состоянии.</li>
        <li>Подать Клиенту автомобиль, управляемый водителем, прошедшим перед рейсом медицинский осмотр и имеющим предусмотренный законодательством стаж вождения.</li>
        <li>В случае аварии или поломки автомобиля, произошедшей при движении к месту Заказа предоставить любой другой автомобиль в кратчайшие сроки к месту Заказа, незамедлительно сообщить предполагаемое время подачи данного автомобиля путем звонка на телефон Клиента.</li>
        <li>В случае аварии или поломки автомобиля, произошедшей в процессе исполнения Заказа предоставить любой другой автомобиль в кратчайшие сроки к месту аварии или поломки.</li>
        <li>В порядке, предусмотренном гл. 10 настоящего Договора объективно рассмотреть претензию клиента.</li>
    </ol>
    <div style={{marginLeft:'10px'}}>3.3 <strong>Права Клиента</strong></div>
    <ol>
        <li>Отказаться от Заказа в силу ст. 28 Закона РФ «О защите прав потребителей» в случае неприбытия автомобиля к положенному времени, либо ко времени, озвученному диспетчером при Заказе.</li>
        <li>Отказаться от Заказа не позднее чем за 24 часа до назначенного времени в Заказе, если необходимость в поездке отпала.</li>
        <li>В случае не прибытия к положенному времени в силу п. 2 ст. 34 Федерального закона Российской Федерации № 259 от 08.11.2007 г. <strong>«Устав автомобильного транспорта и городского наземного электрического транспорта»</strong> (далее – ФЗ № 259) подать Компании заявление об уплате Клиенту штрафа в размере 20% от стоимости поездки в порядке, предусмотренном гл. 10 настоящего Договора.</li>
        <li>Подать Компании претензию относительно качества обслуживания в порядке, предусмотренном гл. 10 настоящего Договора.</li>
    </ol>
    <div style={{marginLeft:'10px'}}>3.4 <strong>Обязанности Клиента</strong></div>
    <ol>
        <li>Полно и подробно изложить суть Заказа (маршрут, время отправления, время прибытия, срочность доставки, количество посадочных мест и грузоемкость и прочие особенности поездки).</li>
        <li>В процессе поездки гарантировать должное поведение всех пассажиров, следить за поведением и состоянием перевозимых животных, обеспечить соответствие багажа Заказу и транспорту.</li>
        <li>Оплатить стоимость Заказа в офисе компании, переводом на карту через Сбербанк-Онлайн или непосредственно водителю Компании при Заказе в день выезда.</li>
        <li>Оплатить штраф в размере 100% от стоимости заказа в случае отказа от текущего Заказа, если автомобиль уже подан.</li>
        <li>Оплатить штраф в размере 50% от стоимости заказа в случае отказа от Заказа, если до времени подачи автомобиля осталось менее 24 часов.</li>
        <li>В случае изменения маршрута, даты, времени, количества пассажиров немедленно известить об этом Компанию по телефону.</li>
    </ol>
    <div><strong>4. Стоимость услуг и порядок расчетов</strong></div>
    <ol>
        <li>По настоящему соглашению стоимость услуги устанавливается после оглашения Заказа Клиентом, доводится до него диспетчером при принятии Заказа. В случае несогласия со стоимостью отказ от услуг принимается только при разговоре с диспетчером.</li>
        <li>Компания оставляет за собой право изменять стоимость услуг в соответствии с общим рынком цен на подобные услуги.</li>
        <li>Оплата производится онлайн банковской картой, в офисе компании, наличными водителю при посадке в автомобиль.</li>
        <li>Оплата производится в рублях.</li>
    </ol>
    <div><strong>5. Расписание движения</strong></div>
    <ol>
        <li>Регулярные рейсы: актуальная информация на сайте <a href="https://alphatour.ru/">http://alphatour.ru</a></li>
        <li>Дополнительные рейсы: отправление в отличное от регулярного времени.</li>
        <li>Точное время подачи транспорта назначает водитель, но не позднее времени отправления рейса.</li>
    </ol>
    <div><strong>6. Сбор пассажиров</strong></div>
    <ol>
        <li>Водитель строит маршрут посадки таким образом, чтобы время занимаемое на сбор всех пассажиров было минимальным.</li>
        <li>В случае, если адрес посадки находится вне черты города отправления или прибытия, то посадка будет совершена в по адресу оговоренной с диспетчером компании.</li>
    </ol>
    <div><strong>7. Срок действия договора</strong></div>
    <ol>
        <li>Настоящий договор вступает в силу с момента его опубликования для перевозчика и для каждого Клиента после осуществления Заказа.</li>
        <li>Настоящий договор прекращает действие после того как Клиент покинул транспортное средство перевозчика, а услуга считается оказанной в полном объеме и Клиент не имеет претензий по качаству оказанной услуги.</li>
    </ol>
    <div><strong>8. Обстоятельства непреодолимой силы</strong></div>
    <ol>
        <li>Соответственно п. 3 ст. 401 ГК РФ стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по настоящему Договору, если таковое явилось следствием обстоятельств непреодолимой силы (форс-мажор), включая аномальные природные условия: гололед, дожди, снегопад, мороз, наводнение, землетрясение и.т.д.; общегородские мероприятия, связанные с перекрытием автодорог и улиц, митинги, забастовки, гражданские волнения, дорожно-транспортные происшествия, военные действия, пожары, любые иные обстоятельства, не ограничиваясь перечисленным, которые могут повлиять на выполнение договора Оферты. Надлежащим доказательством возникновения, прекращения таких обстоятельств и их продолжительности будет являться свидетельство компетентного органа или организации.</li>
        <li>Сторона, для которой создалась невозможность полного или частичного исполнения своих обязательств по настоящему договору обязана немедленно известить другую сторону о начале, предполагаем сроке действия и прекращении вышеуказанных обстоятельств. При этом срок исполнения обязательств по настоящему договору отодвигается соразмерно времени действия таких обстоятельств и их последствий.</li>
        <li>Ни одна из Сторон не отвечает за убытки, которые возникли либо могут возникнуть у второй Стороны в связи с действием обстоятельств непреодолимой силы.</li>
    </ol>
    <div><strong>9. Ответственность сторон</strong></div>
    <ol>
        <li>Все споры и разногласия, которые могут возникнуть в ходе исполнения настоящего договора, будут разрешаться Сторонами путем переговоров.</li>
        <li>При не урегулировании сторонами возникших разногласий спор разрешается в судебном порядке по месту нахождения Компании.</li>
        <li>В случае ненадлежащего исполнения Заказа Перевозчиком несет ответственность в соответствии с законодательством РФ.</li>
        <li>В случае ненадлежащего поведения при исполнении заказа со стороны Клиента, повлекшего конфликт с перевозчиком, а также административную либо уголовную ответственность, Компания оставляет за собой право освещать данные факты в СМИ и интернете, блокировать номер телефона Клиента для предоставления услуг Компанией.</li>
        <li>В соответствии с п. 10 ст. 34 ФЗ № 259 перевозчик освобождается от ответственности за несохранность ручной клади, перевозимой пассажиром, если пассажир не докажет, что несохранность ручной клади произошла по вине перевозчика.</li>
    </ol>
    <div><strong>10. Порядок рассмотрения претензий</strong></div>
    <ol>
        <li>На основании ППРФ № 112 претензии, возникающие в связи с перевозками пассажиров и багажа (далее — Претензии), предъявляются по месту нахождения перевозчика.</li>
        <li>Претензии предъявляются в письменном виде.</li>
        <li>В соответствии с п. 1 ст. 40 ФЗ № 259 перевозчик обязуется рассмотреть предъявленные ей претензии и о результатах их рассмотрения уведомить в письменной форме заявителя в течение тридцати дней со дня получения соответствующей претензии.</li>
        <li>В соответствии со ст. 42 ФЗ № 259 срок исковой давности по требованиям, вытекающим из договоров фрахтования, составляет один год.</li>
    </ol>
    <div><strong>11. Прочие условия</strong></div>
    <ol>
        <li>Во всем, что не оговорено в настоящем договоре, стороны руководствуются действующим законодательством РФ.</li>
        <li>Перевозчик осуществляет запись всех телефонных переговоров с диспетчером в целях контроля качества обслуживания Клиентов.</li>
        <li>Осуществляя заказ, Клиент дает согласие на сбор и обработку персональных данных о себе в целях исполнения условий настоящей Оферты и на информирование (посредством СМС) о акциях, розыгрышах, тарифе перевозчика и их изменений. При сборе и обработке персональных данных Клиентов Перевозчик не преследует иных целей, кроме установленных настоящим пунктом.</li>
        <li>Компания не обязана хранить персональные данные Клиента (статистику, маршруты и т. д.) и вправе их удалить из базы данных.</li>
        <li>Доступ к персональным данным Клиента имеют исключительно лица, имеющие непосредственное отношение к исполнению Заказов.</li>
        <li>Компания оставляет за собой право изменять любые из Условий данного Договора в любое время путем опубликования текста измененного пункта или нового Договора на сайте.</li>
        <li>Новый вариант договора вступает в силу в течение 5 дней после внесения данных изменений на сайте компании.</li>
        <li>В случаях трансфера в аэропорт, автомобиль прибывает минимум за 3 часа до отправления самолета.</li>
    </ol>
    <div><strong>12. Реквизиты компании</strong></div>
    <ol>
        <li>Индивидуальный предприниматель Бадмаев Михаил Викторович</li>
        <li>ИНН 08050065023, ОГРН 306784711600283 р/с 40802810600000025441 в ОАО «ПСКБ» к/с 30101810000000000852, БИК 044030852</li>
        <li>Юридический адрес: 358000, г. Элиста, ул. Ленина 251а</li>
        <li>Телефон: +7 (961) 546 - 60 - 00</li>
    </ol>
    <div><strong>13. Контакты</strong></div>
    <ol>
        <li>Фактический адрес: 358000, г. Элиста, ул. Ленина 251а</li>
        <li>Электронная почта: </li>
        <li>Телефон: +7 (961) 546 - 60 - 00 </li>
    </ol>
    <div><strong>14. Способы оплаты</strong></div>
    <ol>
        <li>Наличный расчёт.</li>
        <div style={{marginLeft:'10px'}}>Оплата наличными в кассе</div>
        <li>Банковской картой.</li>
        <div style={{marginLeft:'10px'}}>Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку Оплата заказа банковской картой. Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт следующих платёжных систем:</div>
        <div style={{marginLeft:'10px'}}>1.МИР</div>
        <div style={{marginLeft:'10px'}}>2.VISA International</div>
        <div style={{marginLeft:'10px'}}>3.Mastercard Worldwide</div>
        <div style={{marginLeft:'10px'}}>4.JCB</div>
    </ol>
    <div><strong>15. Возврат оплаты за услугу</strong></div>
    <ol>
        <li>Возврат переведённых средств, производится на ваш банковский счёт в течение 5-30 рабочих дней (срок зависит от банка, который выдал вашу банковскую карту).</li>
    </ol>
    <div><strong>16. Описание процесса оплаты</strong></div>
    <ol>
        <li>Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure для проведения платежа также может потребоваться ввод специального пароля.</li>
        <li>Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.</li>
    </ol>
    </Policy>);
